/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h3: "h3",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Backup og import av data med Posgresql og Docker"), "\n", React.createElement(_components.p, null, "Trengte data fra produksjon og ned til utviklingsmiljøet. Trengte også å vite hvordan jeg kunen ta backup av databasen. Løste det slik:"), "\n", React.createElement(_components.h3, null, "Logger på digitalocean droplet"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "docker exec <postgres_container_name> pg_dump -F t  -U postgres <database_name> > backup.tar\n")), "\n", React.createElement(_components.h3, null, "Kopierer dump ned lokalt"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "rsync -avz <brukernavn>@<ip til droplet>:./backup.tar <der du vil ha den lokalt>\n")), "\n", React.createElement(_components.h3, null, "Kopierer fil inn i kjørende db-container lokalt"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "docker cp <plassering lokalt> <containernavn/id>:/<plassering i container>\n")), "\n", React.createElement(_components.h3, null, "Dropper db i rails og creater på nytt"), "\n", React.createElement("br"), "\n", React.createElement(_components.h3, null, "Kjører pg_restore i container"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "docker exec <containernavn> pg_restore -U <db brukernavn> -d <db navn> backup.tar\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
